<template>
    <div id="Articles" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="articles_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Artículo
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>

        <div class="col-12 py-2 py-lg-4" v-if="!loading && !fetch_error && !empty_search && articles_data.length > 0">
            <ns-table :data="articles_data" 
                :allow_headers="articles_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:featured="data">
                    <button @click.stop="setFeatured({id_blog: data.item.id_blog, featured: false})" v-if="data.item.featured"
                        class="btn p-0"
                        type="button">
                        <img width="25px" height="25px" :src="star_fill" />
                    </button>
                    <button @click.stop="setFeatured({id_blog: data.item.id_blog, featured: true})" v-if="!data.item.featured"
                        class="btn p-0"
                        type="button">
                        <img width="25px" height="25px" :src="star" />
                    </button>
                </template>
                <template v-slot:active="data">
                    <span>
                        {{ data.item.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </template>
                <template v-slot:publish_status="data">
                    <span>
                        {{ data.item.publish_status == 'unpublished' ? 'Sin publicar' : data.item.publish_status == 'published' ? 'Publicado' : data.item.publish_status == 'programmed' ? 'Programado' : ''}}
                    </span>
                </template>
                <template v-slot:publish_date="data">
                    <span>
                        {{ 
                            data.item.publish_date ? $informativeDate(data.item.publish_date) : 
                                $informativeDate(data.item.creation)
                        }}
                    </span>
                </template>
                <template v-slot:schedule_date="data">
                    <span>
                        {{ new Date(data.item.schedule_date).toLocaleDateString()}}
                    </span>
                </template>
                <template v-slot:picture="data">
                    <div class="flex-center">
                        <div style="max-width: 60px;">
                            <img :src="data.value" 
                                class="img-fluid">
                        </div>
                    </div>
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center" >
                        <div class="col-12 position-relative" style="height: 50px;">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 150px; z-index: 50; top: -5vh; right: 0vw;" v-if="data.item.open">

                                <button @click.stop="selectTableItem('detail', data.item.id_blog)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Detalles
                                </button>

                                <button @click.stop="selectTableItem('edit', data.item.id_blog, data.item)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id_blog)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id_blog)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>
                        </div>

                    </div>
                </template>
            </ns-table>
        </div>


        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="articles_modal" bg_color="white" :max_width="1200"
            overlay_close @onClose="articles_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-input label="Título" 
                        theme="light"
                        v-model="title"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.title">
                        {{ errors.title}}
                    </span>
                </div>

                <div class="col-12 col-md-6 pr-4 pl-4">
                    <ns-select label="Categoría"
                        theme="light"
                        :options="category_options"
                        v-model="category_id"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.category_id">
                        {{ errors.category_id }}
                    </span>
                </div>
                

                <div class="col-12 pr-4 pl-4">
                    <ns-select label="Autor"
                        theme="light"
                        :options="author_options"
                        v-model="author"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.author">
                        {{ errors.author }}
                    </span>
                </div>

                <div class="col-12 col-md-4 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Descripción
                    </label>
                    <textarea class="form-control bg-light  w-100"
                        rows="10"
                        style="border: none; border-radius: 15px;"
                        v-model="description">
                    </textarea>
                    <span class="text-danger" v-if="errors.description">
                        {{ errors.description }}
                    </span>
                </div>

                <div class="col-12 col-md-4 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Imagen Destacada
                    </label>
                    <ns-dropfile 
                        _id="image_1"
                        @charged="checkFile"
                        style="height: 255px;"
                        variant="success">
                    </ns-dropfile>
                </div>

                <div class="col-12 col-md-4 pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Preview
                    </label>
                    <div style="max-width:300px;">
                        <img class="img-fluid"
                        :src="preview_image" />
                    </div>
                </div>

                <div class="col-12  pr-4 pl-4 my-3">
                    <label class="text-dark">
                        Cuerpo
                    </label>
                    <textarea class="form-control bg-light  w-100"
                        rows="8"
                        style="border: none; border-radius: 15px;"
                        v-model="body">
                    </textarea>
                    <span class="text-danger" v-if="errors.body">
                        {{ errors.body }}
                    </span>
                </div>

                <div class="col-12 col-md-4 pr-4 pl-4">
                    <ns-input label="Etiquetas:" 
                        theme="light"
                        v-model="tags"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.tags">
                        {{ errors.tags }}
                    </span>
                </div>

                <div class="col-12 col-md-4 pr-4 pl-4">
                    <ns-select label="Estado:"
                        theme="light"
                        :options="[{id: 'Seleccione', label: 'Seleccione'},{id: 'unpublished', label: 'Sin publicar'},{id: 'published', label: 'Publicado'},{id: 'programmed', label: 'Programado'}]"
                        v-model="publish_status"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.publish_status">
                        {{ errors.publish_status }}
                    </span>
                </div>

                <div class="col-12 col-md-4 pr-4 pl-4">
                    <ns-input v-if="publish_status == 'programmed'"
                        label="Fecha programada" 
                        theme="light"
                        v-model="publish_date"
                        type="date"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.publish_date">
                        {{ errors.publish_date }}
                    </span>
                </div>


                <div class="col-12 mt-4 montserrat-bold">
                    <div class="row justify-content-end align-items-center h-100">
                        <div class="col-4 d-flex justify-content-between px-4">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="detail_modal" bg_color="white" max_width="680"
            overlay_close @onClose="detail_modal = false">
            <div class="row w-100 justify-content-between montserrat-regular text-dark">

                <div class="col-12" style="font-size: 22px;">
                    <p class="text-black montserrat-semibold">
                        Detalles
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Autor:</strong>  
                       <span class="d-block">
                            {{ article.author_name}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Título:</strong>  
                       <span class="d-block">
                            {{ article.title}}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Descripción:</strong>  
                       <span class="d-block">
                            {{ article.description }}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Cuerpo:</strong>  
                       <span class="d-block">
                            {{ article.body }}
                       </span>
                    </p>
                </div>

                <div class="col-12">
                    <p class="underline_p">
                       <strong>Etiquetas:</strong>  
                       <span class="d-block">
                            {{ article.tags }}
                       </span>
                    </p>
                </div>

                <div class="col-6">
                    <p class="underline_p">
                       <strong>Categoría:</strong>  
                       <span class="d-block">
                            {{ article.blog_category_name}}
                       </span>
                    </p>
                </div>

                <div class="col-6 ">
                    <p class="underline_p">
                       <strong>Estado:</strong>  
                       <span class="d-block">
                            {{ article.publish_status == 'unpublished' ? 'Sin publicar' : article.publish_status == 'published' ? 'Publicado' : article.publish_status == 'programmed' ? 'Programado' : '' }}
                       </span>
                    </p>
                </div>

                <div class="col-6">
                    <p class="underline_p">
                       <strong>Fecha programada:</strong>  
                       <span class="d-block">
                            {{ new Date(article.publish_date).toLocaleDateString() }}
                       </span>
                    </p>
                </div>



                <div class="col-12 d-flex justify-content-end">
                    <button @click.stop="detail_modal = false"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cerrar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import star from 'ASSETS/icons/star.png'
    import star_fill from 'ASSETS/icons/star_fill.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon_b,
                arrow_left,
                star,
                star_fill,
                articles_modal: false,
                delete_modal: false,
                active_modal: false,
                detail_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                articles_headers: [
                    {key: 'title', label: 'Título', width: 20},
                    {key: 'author_name', label: 'Autor'},
                    {key: 'publish_date', label: 'Fecha'},
                    {key: 'publish_status', label: 'Estado'},
                    {key: 'featured', label: 'Destacado', width: 5},
                    {key: 'tags', label: 'Etiquetas'},
                    {key: 'picture', label: 'Imagen'},
                    {key: 'active', label: 'Estatus', width: 5},
                    {key: 'actions', label: 'Acciones', width: 5}
                ],
                articles_data: [],
                author_options: [],
                category_options: [],
                title: null,
                author: '0',
                category_id: '0',
                body: null,
                description: null,
                publish_date: null,
                publish_status: null,
                tags: null,
                preview_image: null,
                selected_id: null,
                errors: {
                    title: null,
                    author: null,
                    category_id: null,
                    body: null,
                    description: null,
                    publish_date: null,
                    publish_status: null,
                    tags: null,
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.title = null
                this.author = null
                this.category_id = '0'
                this.body = null
                this.description = null
                this.preview_image = null
                this.status = null
                this.schedule = null
                this.date = null
                this.selected_id = null

                this.articles_modal = false;
                this.delete_modal = false;
                this.active_modal = false;
            },
            checkFile(file){
                this.preview_image = file.url;
                this.thumbnail = file.url
            },
            async selectTableItem(type, id, data = null){
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'detail':
                        await this.$store.dispatch('blog_posts/view', { id_blog: id})
                        this.detail_modal = true
                        break;
                    case 'edit':
                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        this.selected_id = id
                        await this.$store.dispatch('blog_posts/view', { id_blog: this.selected_id})
                        this.title = this.article.title
                        this.category_id = this.article.id_blog_category.toString()
                        this.body = this.article.body
                        this.author = this.article.id_author.toString()
                        this.description = this.article.description
                        this.preview_image = this.article.picture
                        this.publish_status = this.article.publish_status
                        this.thumbnail = null
                        this.publish_date = this.formatDate(this.article.publish_date)
                        this.tags = this.article.tags
                        this.articles_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements(){
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('blog_posts/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('blog_posts/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('blog_posts/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.articles === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.articles_data = this.articles.map( category => {
                    return {
                        ...category,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            // posts CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.title) || _.isEmpty(this.title)) {
                    this.errors.title = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.category_id) || _.isEmpty(this.category_id) || this.category_id == '0') {
                    this.errors.category_id = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.body) || _.isEmpty(this.body)) {
                    this.errors.body = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.description) || _.isEmpty(this.description)) {
                    this.errors.description = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.publish_status) || _.isEmpty(this.publish_status)  && this.publish_status == 'Seleccione') {
                    this.errors.publish_status = '*Campo requerido'
                    complete = false
                }

                else if(this.publish_status == 'programmed' && (_.isNull(this.publish_date) || _.isEmpty(this.publish_date))) {
                    this.errors.publish_date = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.tags) || _.isEmpty(this.tags)) {
                    this.errors.tags = '*Campo requerido'
                    complete = false
                }

                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                title: this.title,
                                id_blog_category: this.category_id,
                                id_author: this.author,
                                body: this.body,
                                description: this.description,
                                publish_date: this.publish_date,
                                publish_status: this.publish_status,
                                picture: this.thumbnail,
                                tags: this.tags
                            },
                            query: {
                                id_blog: this.selected_id
                            }
                        }

                        await this.$store.dispatch('blog_posts/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {
                        let payload = {
                            title: this.title,
                            id_blog_category: this.category_id,
                            id_author: this.author,
                            body: this.body,
                            description: this.description,
                            publish_date: this.publish_date,
                            publish_status: this.publish_status,
                            picture: this.thumbnail,
                            tags: this.tags
                        }
                        
                        await this.$store.dispatch('blog_posts/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('blog_posts/delete', { id_blog: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },

            async activateElement() {
                await this.$store.dispatch('blog_posts/delete', { id_blog: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },
            async setFeatured(data) {
                await this.$store.dispatch('blog_posts/featured', data)
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        computed: {
            ...mapGetters({
                authors: 'authors/getAuthors',
                categories: 'blog_categories/getCategories',
                article: 'blog_posts/getArticle',
                articles: 'blog_posts/getArticles',
                last_page: 'blog_posts/getLastPage',
                total_pages: 'blog_posts/getTotalPages'
            })
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'ARTÍCULOS')
                this.$store.commit('setIndex', 2)

                //list of articles(blog posts)
                await this.$store.dispatch('blog_posts/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, status: 'active'})
                if(this.articles) {
                    this.articles_data = this.articles.map( post => {
                    return {
                        ...post,
                        actions: '-',
                        open: false
                        }
                    })
                }


                //author options
                await this.$store.dispatch('authors/list', { datatable: false, status: 'active'})
                if(this.author_options) {
                    this.author_options = this.authors.map( author => {
                        return {
                            id: author.id_author,
                            label: `${author.first_name} ${author.last_name} ${author.second_surname} - ${author.specialty}`
                        }
                    })
                }

                else {
                    this.author_options = [{id: null, label: 'Natura Slim'}]
                }
                

                //categories options
                await this.$store.dispatch('blog_categories/list', { datatable: false, status: 'active'})
                if(this.categories) {
                    this.category_options = this.categories.map( category => {
                        return {
                            id: category.id,
                            label: category.name
                        }
                    })
                }

                else {
                    this.category_options = [{ id: '0', label: 'Sin categorias disponibles'}]
                }
                

                
                
                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

</style>